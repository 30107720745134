import { useCallback, useEffect, useRef, useState } from "react";
import { FakeWidjet } from "../../modules/Widjet/FakeWidjet";
import { Widjet } from "../../modules/Widjet/Widjet";
import { useStore } from "zustand";
import { getTimeInHotel } from "./api/getTimeInHotel";
import { WidjetContext } from "../../store/Widjet/widjetContext";
import { useBookingModule } from "../../hooks/useBookingModule";
import { useWidjetStore } from "../../store/widjet/useWidjetStore";
import { ErrorWidjet } from "../../modules/Widjet/ErrorWidjet";
import { createPortal } from "react-dom";

export function WidjetPage() {
    const store = useRef(useWidjetStore()).current;

    const [loading, setLoading] = useState(false);

    const setDateFrom = useStore(store, state => state.setDateFrom);
    const setDateTo = useStore(store, state => state.setDateTo);
    const setRooms = useStore(store, state => state.setRooms);
    const setTimeSettings = useStore(store, state => state.setTimeSettings);
    const updateBookingSettings = useStore(store, state => state.updateBookingSettings);
    const updateRoomsLimits = useStore(store, state => state.updateRoomsLimits);

    const isFailed = useStore(store, state => state.isFailed);
    const setIsFailed = useStore(store, state => state.setIsFailed);

    const dateFrom = useStore(store, state => state.dateFrom);
    const dateTo = useStore(store, state => state.dateTo);

    const { isModuleOpen, setIsModuleOpen } = useBookingModule();

    useEffect(() => {
        function popstateHandler(e) {
            if (!e.state?.lv_module_open) {
                setIsModuleOpen(false)
            }
        }

        window.addEventListener('popstate', popstateHandler);

        return () => {
            window.removeEventListener('popstate', popstateHandler);
        }
    }, [isModuleOpen, setIsModuleOpen]);

    const uploadSettings = useCallback(() => {
        getTimeInHotel()
            .then(data => {
                const offsetDiff = new Date().getTimezoneOffset() + data.offset;
                const today = new Date(new Date().getTime() + (offsetDiff * 60 * 1000));
                setTimeSettings(data);

                let df = new Date(new Date(today).setHours(0, 0, 0, 0));
                let dt = new Date(new Date(new Date(today.getTime() + 86400000)).setHours(0, 0, 0, 0));

                if (!dateFrom || !dateTo) {
                    setDateFrom(df);
                    setDateTo(dt);

                } else if (df.getTime() > dateFrom.getTime() || dt.getTime() > dateTo.getTime()) {
                    setDateFrom(df);
                    setDateTo(dt);
                }
            })
            .then(() => updateBookingSettings())
            .then(() => updateRoomsLimits())
            .then(() => setLoading(true))
            .catch(() => {
                setIsFailed(true)
            })
    }, [setDateFrom, setDateTo, setTimeSettings, updateRoomsLimits, updateBookingSettings, setIsFailed])

    useEffect(() => {
        uploadSettings();
    }, [uploadSettings]);

    const [moduleIframe, setModuleIframe] = useState(null);

    function refreshCb() {
        setLoading(false)
        setIsFailed(false)
        uploadSettings()
    }

    useEffect(() => {
        function messageHandler(e) {
            if (e.data.method === "redirect") {
                window.location.replace(e.data.paymentURL)
            } else if (e.origin === process.env.SITE_ORIGIN) {
                if (e.data.dateFrom && e.data.dateTo && e.data.rooms) {
                    setDateFrom(new Date(e.data.dateFrom))
                    setDateTo(new Date(e.data.dateTo))
                    setRooms(e.data.rooms);
                }
            }
        }

        window.addEventListener("message", messageHandler);

        return () => {
            window.removeEventListener("message", messageHandler);
        }
    }, [])

    return (
        <WidjetContext.Provider value={store}>
            {(loading && !isFailed) ? <Widjet setIsModuleOpen={setIsModuleOpen} setModuleIframe={setModuleIframe} setIsFailed={setIsFailed} /> : ""}
            {(!loading && !isFailed) ? <FakeWidjet /> : ""}
            {isFailed ? <ErrorWidjet callback={refreshCb}></ErrorWidjet> : ""}
            {isModuleOpen && createPortal(moduleIframe, document.body, "bm-widjet-module")}
        </WidjetContext.Provider>
    )
}